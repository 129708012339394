import React, { Component } from 'react';
import bg_1 from './bg_1.png';
import bg_2 from './bg_2.png';
import bg_3 from './bg_3.png';
import bg_4 from './bg_4.png';
import logo from './logo.png';
import button1 from './Get_it_on_Google_play.png';
import button2 from './Available_on_the_App_Store.png';
import button3 from './ic_fb.png';
import button4 from './ic_g+.png';
import button5 from './ic_tweet.png';
import phone from './phone_1.png';
import phone1 from './phone_2.png';
import phone2 from './phone_3.png';
import phone4 from './phone_4.png';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import { ResponsiveComponent } from "react-responsive-component"


import './App.css';

class App extends Component {
  state ={
    hovered:false,
    hovered2:false

  }
  onMouseOver =  () => {
    this.setState({ hovered:true });
  }

  onMouseOut =  () => {
    this.setState({ hovered:false });
  }
  onMouseOver2 =  () => {
    this.setState({ hovered2:true });
  }

  onMouseOut2 =  () => {
    this.setState({ hovered2:false });
  }
  render() {
    return (
    <div className='wrap-content'>
       <ResponsiveComponent query="only screen and (min-width: 900px)">
      <div className="promo" style={{padding:'0px'}}>
        <div className="header" >
          <img src={logo} alt="logo" style={{width:273,height:70}}>
          </img>
          {/*<div style={{flexDirection:'row',display:'flex'}}>
            <h4 onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} style={this.state.hovered?{marginRight:'30px',color:'gold'}:{marginRight:'30px'}}>Twitter</h4>
            <h4 onMouseOver={this.onMouseOver2} onMouseOut={this.onMouseOut2} style={this.state.hovered2?{marginRight:'30px',color:'gold'}:{marginRight:'30px'}}>Contact</h4>
          </div>*/}
          
        </div>
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'50px'}} >
          <div style={{width:'45%',height:'300px',display:'flex',flexDirection:'column'}} >
            <ScrollAnimation animateIn='fadeIn'
            animateOut='fadeOut'>
            <h1 style={{width:'70%',textAlign:'left',fontSize:'35'}}>View only you wanna view .</h1>
              <div style={{width:'19%',height:'10px',background:'white',borderRadius:10}} />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn'
            animateOut='fadeOut'>
              <h3 style={{width:'100%',textAlign:'left',fontWeight:'normal'}}>Newest and coolest social networking. Only see and view what you wanna see.
                Share your own breathaking photos, videos or graphics.
                Shares and enjoy it!
              </h3>
            </ScrollAnimation>
            <div style={{display:'flex',flexDirection:'row'}}>
              <ScrollAnimation animateIn='fadeIn'
                initiallyVisible={true}
                animateOut='fadeOut'>
                  <a href='https://play.google.com/store/apps/details?id=com.viugraph.mobile'>
                    <img src={button1} alt="button_1" style={{width:110,height:34,marginRight:'10px'}} />
                  </a>
                  <img src={button2} alt="button_2" style={{width:110,height:34}} />
                  <h4 style={{fontSize:'8',position:'absolute',marginLeft:'240px',marginTop:'-30px'}}>for iPhone coming soon in couple weeks</h4>

              </ScrollAnimation>
            </div>
          </div>

          <div style={{width:'45%',height:'300px'}} >
          </div>
        </div>
        <img src={bg_1} alt="bg_1" style={{width:'100%',position:'absolute'}} />
      </div>
      
      <div style={{width:'45%',height:'300px',float:'right',marginTop:'-495px',position:'absolute',zIndex:99,left:'65%'}} >
        <ScrollAnimation animateIn='fadeIn'
        initiallyVisible={true}
        animateOut='fadeOut'>
        <img src={phone} alt="phone" style={{zIndex:1000}}/>
        </ScrollAnimation>
      </div>
       <div className="promo2" style={{padding:'0px',marginTop:'-190px',background:'#efefef'}}>
        
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'13%'}} >
          <div style={{width:'45%',height:'300px',float:'left',marginRight:'150px'}} >
            <ScrollAnimation animateIn='fadeIn'
              initiallyVisible={true}
              animateOut='fadeOut'>
            <img src={phone1} alt="phone_1" style={{zIndex:1000,width:'360px'}}/>
            </ScrollAnimation>
          </div>
          <div style={{width:'45%',height:'300px',display:'flex',flexDirection:'column'}} >
            <ScrollAnimation animateIn='fadeIn'
              animateOut='fadeOut'
              initiallyVisible={true}>
            <h1 style={{width:'70%',color:'#5a97d2',textAlign:'left',textShadow:'#ffffff',fontSize:'35'}}>Share graphics, photos and video in coolest way .</h1>
            <div style={{width:'25%',height:'10px',background:'#5a97d2',borderRadius:10}} />
            <h3 style={{width:'100%',textAlign:'left',color:'#5a97d2',fontWeight:'thin',textShadow:'#ffffff'}}>
              New way to share your photo and videos in a cozy platform of Social networking. Feel the differents and unique tools to share your awesomeness.
            </h3>
            </ScrollAnimation>         
          </div>

          
        </div>
        <img src={bg_2} alt="bg_2" style={{width:'100%',position:'absolute',marginTop:'35%'}} />
      </div>
      <div className="promo2" style={{padding:'0px',marginTop:'-190px'}}>
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'18%'}} >
          <div style={{width:'45%',height:'300px',display:'flex',flexDirection:'column'}} >
          <ScrollAnimation animateIn='flipInY'
              initiallyVisible={true}
              animateOut='flipOutY'>
            <h1 style={{width:'70%',textAlign:'left'}}>Light App and data friendly .</h1>
            <div style={{width:'19%',height:'10px',background:'white',borderRadius:10}} />
            <h3 style={{width:'100%',textAlign:'left',fontWeight:'normal'}}>
            Don't worry to enjoy photo and videos. This apps is data lightweight.
            </h3>
          </ScrollAnimation>
          </div>

          <div style={{width:'45%',float:'right',marginLeft:'15%',marginTop:'0%',marginRight:'-16%'}} >
            <ScrollAnimation animateIn='fadeIn'
              initiallyVisible={true}
              animateOut='fadeOut'>
            <img src={phone2} alt="phone_2" style={{width:'476px',height:'457',}} />
            </ScrollAnimation>
          </div>
        </div>
        <img src={bg_3} alt="bg_3" style={{width:'100%',position:'absolute'}} />
      </div>
      <div className="promo2" style={{padding:'0px',marginTop:'-190px',background:'#efefef'}}>
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'13%'}} >
          <div style={{width:'45%',height:'300px',float:'left',marginRight:'150px'}} >
          <ScrollAnimation animateIn='fadeIn'
            initiallyVisible={true}
            animateOut='fadeOut'>
            <img src={phone4} alt="phone_4" style={{zIndex:1000,width:'360px'}}/>
          </ScrollAnimation>
          </div>
          <div style={{width:'45%',height:'300px',display:'flex',flexDirection:'column'}} >
          <ScrollAnimation animateIn='flipInY'
            animateOut='flipOutY fadeOut'
            initiallyVisible={true} >
            <h1 style={{width:'70%',color:'#5a97d2',textAlign:'left',textShadow:'#ffffff'}}>Beyond imagination.</h1>
            <div style={{width:'22%',height:'10px',background:'#5a97d2',borderRadius:10}} />
            <h3 style={{width:'100%',textAlign:'left',color:'#5a97d2',fontWeight:'normal',textShadow:'#ffffff'}}>Boost your creativity: express what your feel in a new and creative way.</h3>
          </ScrollAnimation>
          </div>

          
        </div>
        <img src={bg_4} alt="bg_4" style={{width:'100%',position:'absolute'}} />
      </div>
       <div className="promo3" style={{padding:'0px',marginTop:'-190px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div style={{width:'45%',height:'300px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
            <h1 style={{width:'70%',textAlign:'center'}}>Subscribe for updates</h1>
            <div style={{width:'22%',height:'10px',background:'white',borderRadius:10}} />
            <h3 style={{width:'100%',textAlign:'center',fontWeight:'normal'}}>info (at) svarga-mediatama.com</h3>
             <div style={{display:'flex',flexDirection:'row'}}>
              <a href="https://www.facebook.com/viugraph/" target="_blank">
                <img src={button3} style={{width:40,height:40,marginRight:'10px'}} />
              </a>
              <a href="https://plus.google.com/collection/ww-vIE" target="_blank">
                <img src={button4} style={{width:40,height:40,marginRight:'10px'}} />
              </a>
              <a href="https://twitter.com/ViuGraph" target="_blank">
                <img src={button5} style={{width:40,height:40}} />
              </a>
            </div>
          </div>
      </div>
      <div className='footer' style={{width:'100%',height:'80px',marginTop:'-76px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} >
        {/*<h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px'}}>About</h4>*/}
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/svarga-indomulia-mediatama.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>About Us</a>
        </h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/privacy-policy.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>Privacy Policy</a>
        </h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/term-of-services.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>Term of Services</a>
        </h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/frequently-asked-questions.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>Frequently Asked Questions</a>
        </h4>
        {/*<h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px'}}>Terms</h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff'}}>Contact us</h4>*/}
      </div>
      </ResponsiveComponent>
    {/*ukuran >900px*/}
      <ResponsiveComponent query="only screen and (max-width: 900px)">
       <ResponsiveComponent query="only screen and (min-width: 700px)">
        <div className="promo" style={{padding:'0px'}}>
        <div style={{background:'url('+bg_1+') no-repeat center center',backgroundSize:'cover',width:'100%',height:'100%'}}>
          <div className="header" >
            <img src={logo} style={{width:273,height:70,marginLeft:'30px'}}>
            </img>
            {/*<div style={{flexDirection:'row',display:'flex'}}>
              <h4 onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} style={this.state.hovered?{marginRight:'30px',color:'gold'}:{marginRight:'30px'}}>Twitter</h4>
              <h4 onMouseOver={this.onMouseOver2} onMouseOut={this.onMouseOut2} style={this.state.hovered2?{marginRight:'30px',color:'gold'}:{marginRight:'30px'}}>Contact</h4>
            </div>*/}
            
          </div>
          <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'50px'}} >
            <div style={{width:'45%',height:'300px',display:'flex',flexDirection:'column'}} >
              <ScrollAnimation animateIn='fadeIn'
              animateOut='fadeOut'>
              <h1 style={{width:'100%',textAlign:'left',fontSize:'25'}}>View only you wanna view .</h1>
                <div style={{width:'19%',height:'10px',background:'white',borderRadius:10}} />
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeIn'
              animateOut='fadeOut'>
                <h3 style={{width:'100%',textAlign:'left',fontWeight:'normal'}}>
                  Newest and coolest social networking. Only see and view what you wanna see.
                  Share your own breathaking photos, videos or graphics.
                  Shares and enjoy it!
                </h3>
              </ScrollAnimation>
              <div style={{display:'flex',flexDirection:'row'}}>
                <ScrollAnimation animateIn='fadeIn'
                  initiallyVisible={true}
                  animateOut='fadeOut'>
                  <a href='https://play.google.com/store/apps/details?id=com.viugraph.mobile'>
                    <img src={button1} style={{width:110,height:34,marginRight:'10px'}} />
                  </a>
                    <img src={button2} style={{width:110,height:34}} />
                    <h4 style={{fontSize:'7',position:'absolute',marginLeft:'240px',marginTop:'-30px'}}>for iPhone coming soon <br/> in couple weeks</h4>

                </ScrollAnimation>
              </div>
            </div>

            <div style={{width:'45%',height:'300px'}} >
              <ScrollAnimation animateIn='fadeIn'
              initiallyVisible={true}
              animateOut='fadeOut'>
              <img src={phone} style={{width:'300px'}}/>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className="promo2" style={{padding:'0px',marginTop:'-175px',background:'#efefef'}}>
        
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'30%'}} >
          <div style={{width:'45%',height:'300px',float:'left'}} >
            <ScrollAnimation animateIn='fadeIn'
              initiallyVisible={true}
              animateOut='fadeOut'>
            <img src={phone1} style={{width:'300px',marginLeft:'-100px'}}/>
            </ScrollAnimation>
          </div>
          <div style={{width:'45%',height:'300px',display:'flex',flexDirection:'column'}} >
            <ScrollAnimation animateIn='fadeIn'
              animateOut='fadeOut'
              initiallyVisible={true}>
            <h1 style={{width:'100%',color:'#5a97d2',textAlign:'left',textShadow:'#ffffff',fontSize:'25'}}>Share graphics, photos and video in coolest way .</h1>
            <div style={{width:'30%',height:'10px',background:'#5a97d2',borderRadius:10}} />
            <h3 style={{width:'100%',textAlign:'left',color:'#5a97d2',fontWeight:'thin',textShadow:'#ffffff'}}>
            New way to share your photo and videos in a cozy platform of Social networking. Feel the differents and unique tools to share your awesomeness.
            </h3>
            </ScrollAnimation>         
          </div>

          
        </div>
        <img src={bg_2} style={{width:'100%',position:'absolute',marginTop:'60%'}} />
      </div>

      <div className="promo2" style={{padding:'0px',marginTop:'-175px'}}>
        <div style={{background:'url('+bg_3+') no-repeat center center',backgroundSize:'cover',width:'100%',height:'100%'}}>
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'30%'}} >
          <div style={{width:'45%',height:'300px',display:'flex',flexDirection:'column'}} >
          <ScrollAnimation animateIn='flipInY'
              initiallyVisible={true}
              animateOut='flipOutY'>
            <h1 style={{width:'100%',textAlign:'left',fontSize:'25'}}>Light App and data friendly .</h1>
            <div style={{width:'19%',height:'10px',background:'white',borderRadius:10}} />
            <h4 style={{width:'100%',textAlign:'left',fontWeight:'normal'}}>Don't worry to enjoy photo and videos. This apps is data lightweight.</h4>
          </ScrollAnimation>
          </div>

          <div style={{width:'45%',float:'right',marginLeft:'20%',marginTop:'10%',marginRight:'-16%'}} >
            <ScrollAnimation animateIn='fadeIn'
              initiallyVisible={true}
              animateOut='fadeOut'>
            <img src={phone2} style={{width:'376px',height:'357',}} />
            </ScrollAnimation>
          </div>
        </div>
        </div>
      </div>
      <div className="promo2" style={{padding:'0px',marginTop:'-190px',background:'#efefef'}}>
        <div style={{background:'url('+bg_4+') no-repeat center center',backgroundSize:'cover',width:'100%',height:'100%'}}>
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'30%'}} >
          <div style={{width:'45%',height:'300px',float:'left',marginRight:'70px',marginLeft:'20px'}} >
          <ScrollAnimation animateIn='fadeIn'
            initiallyVisible={true}
            animateOut='fadeOut'>
            <img src={phone4} style={{width:'300px'}}/>
          </ScrollAnimation>
          </div>
          <div style={{width:'50%',height:'300px',display:'flex',flexDirection:'column'}} >
          <ScrollAnimation animateIn='flipInY'
            animateOut='flipOutY fadeOut'
            initiallyVisible={true}>
            <h1 style={{width:'100%',color:'#5a97d2',textAlign:'left',textShadow:'#ffffff',fontSize:'25px'}}>Beyond imagination .</h1>
            <div style={{width:'22%',height:'10px',background:'#5a97d2',borderRadius:10}} />
            <h4 style={{width:'100%',textAlign:'left',color:'#5a97d2',fontWeight:'normal',textShadow:'#ffffff'}}>Boost your creativity: express what your feel in a new and creative way.</h4>
          </ScrollAnimation>
          </div>

          
        </div>
        </div>
      </div>
       <div className="promo3" style={{padding:'0px',marginTop:'-190px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div style={{width:'45%',height:'300px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
            <h1 style={{width:'100%',textAlign:'center',fontSize:'25px'}}>Subscrib for updates</h1>
            <div style={{width:'22%',height:'10px',background:'white',borderRadius:10}} />
            <h4 style={{width:'100%',textAlign:'center',fontWeight:'normal'}}>info (at) svarga-mediatama.com</h4>
             <div style={{display:'flex',flexDirection:'row'}}>
              <a href="https://www.facebook.com/viugraph/" target="_blank">
                <img src={button3} style={{width:40,height:40,marginRight:'10px'}} />
              </a>
              <a href="https://plus.google.com/collection/ww-vIE" target="_blank">
                <img src={button4} style={{width:40,height:40,marginRight:'10px'}} />
              </a>
              <a href="https://twitter.com/ViuGraph" target="_blank">
                <img src={button5} style={{width:40,height:40}} />
              </a>
            </div>
          </div>
      </div>
      <div className='footer' style={{width:'100%',height:'80px',marginTop:'-76px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} >
        {/*<h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px'}}>About</h4>*/}
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/svarga-indomulia-mediatama.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>About Us</a>
        </h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/privacy-policy.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>Privacy Policy</a>
        </h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/term-of-services.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>Term of Services</a>
        </h4>
        {/*<h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px'}}>Terms</h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff'}}>Contact us</h4>*/}
      </div>
      </ResponsiveComponent>

      <ResponsiveComponent query="only screen and (max-width: 700px)">
        <div className="promo" style={{padding:'0px'}}>
        <div style={{background:'url('+bg_1+') no-repeat center center',backgroundSize:'cover',width:'100%',height:'100%'}}>
          <div className="header2" >
            <img src={logo} style={{width:203,height:50,marginLeft:'25px'}}>
            </img>
            {/*<div style={{flexDirection:'row',display:'flex',marginLeft:'8%'}}>
              <h5 onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} style={this.state.hovered?{marginRight:'30px',color:'gold'}:{marginRight:'10px'}}>Twitter</h5>
              <h5 onMouseOver={this.onMouseOver2} onMouseOut={this.onMouseOut2} style={this.state.hovered2?{marginRight:'30px',color:'gold'}:{marginRight:'10px'}}>Contact</h5>
            </div>*/}
            
          </div>
          <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginTop:'40px'}} >
            <div style={{width:'100%',height:'300px',display:'flex',flexDirection:'column',marginLeft:'15%'}} >

              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                <h1 style={{width:'100%',textAlign:'left',fontSize:'20'}}>View only you wanna view .</h1>
                <div style={{width:'19%',height:'5px',background:'white',borderRadius:10}} />
              </ScrollAnimation>

              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                <h3 style={{width:'100%',textAlign:'left',fontWeight:'normal',fontSize:'14'}}>
                Newest and coolest social networking. Only see and view what you wanna see.
                Share your own breathaking photos, videos or graphics.
                Shares and enjoy it!
                </h3>
              </ScrollAnimation>

              <div style={{display:'flex',flexDirection:'row'}}>
                <ScrollAnimation animateIn='fadeIn'
                  initiallyVisible={true}
                  animateOut='fadeOut'>
                  <a href='https://play.google.com/store/apps/details?id=com.viugraph.mobile'>
                    <img src={button1} style={{zIndex:100,width:80,height:25,marginRight:'10px'}} />
                  </a>
                  <img src={button2} style={{width:80,height:25}} />
                  <h4 style={{fontSize:'8',position:'absolute',marginLeft:'180px',marginTop:'-20px'}}>for iPhone coming soon in couple weeks</h4>

                </ScrollAnimation>
              </div>

              <div style={{marginTop: 50}}>
                <ScrollAnimation animateIn='fadeIn' initiallyVisible={true}
                  animateOut='fadeOut' style={{marginTop: 50}}>
                  <img src={phone} style={{width:'200px'}}/>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="promo2" style={{padding:'0px',marginTop:'-175px',background:'#efefef'}}>
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'290px'}} >
          {/*<div style={{width:'0%',height:'300px',float:'left',marginRight:'150px'}} >
            <ScrollAnimation animateIn='fadeIn'
              initiallyVisible={true}
              animateOut='fadeOut'>
            <img src={phone1} style={{zIndex:1000,width:'200px'}}/>
            </ScrollAnimation>
          </div>*/}
          <div style={{width:'100%',height:'300px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
            <ScrollAnimation animateIn='fadeIn'
              initiallyVisible={true}
              animateOut='fadeOut'>
            <img src={phone1} style={{width:'200px',float:'left'}}/>
            </ScrollAnimation> 
            <ScrollAnimation animateIn='fadeIn'
              animateOut='fadeOut'
              initiallyVisible={true}>
            <h1 style={{width:'100%',color:'#5a97d2',textAlign:'left',textShadow:'#ffffff',fontSize:'20px'}}>Share graphics, photos and video in coolest way .</h1>
            <div style={{width:'25%',height:'5px',background:'#5a97d2',borderRadius:10}} />
            <h3 style={{width:'100%',textAlign:'left',color:'#5a97d2',fontWeight:'thin',fontSize:'14px'}}>
              New way to share your photo and videos in a cozy platform of Social networking. Feel the differents and unique tools to share your awesomeness.            </h3>
            </ScrollAnimation>
                    
          </div>
          
          
        </div>
        <img src={bg_2} style={{width:'100%',position:'absolute',marginTop:'600px'}} />
      </div>

      <div className="promo2" style={{padding:'0px',marginTop:'-175px'}}>
        <div style={{background:'url('+bg_3+') no-repeat center center',backgroundSize:'cover',width:'100%',height:'100%'}}>
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'row',alignItems:'space-between',justifyContent:'center',marginTop:'50%'}} >
          <div style={{width:'100%',height:'300px',display:'flex',flexDirection:'column',marginLeft:'20px'}} >
          <ScrollAnimation animateIn='flipInY'
              initiallyVisible={true}
              animateOut='flipOutY'>
            <h1 style={{width:'100%',textAlign:'left',fontSize:'20'}}>Light App and data friendly .</h1>
            <div style={{width:'19%',height:'5px',background:'white',borderRadius:10}} />
            <h4 style={{width:'100%',textAlign:'left',fontWeight:'normal',fontSize:'14px'}}>Don't worry to enjoy photo and videos. This apps is data lightweight.</h4>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn'
              initiallyVisible={true}
              animateOut='fadeOut'>
            <img src={phone2} style={{width:'276px',height:'70%',marginLeft:'16%'}} />
          </ScrollAnimation>
          </div>
        </div>
        </div>
      </div>
      <div className="promo2" style={{padding:'0px',marginTop:'-190px',background:'#efefef'}}>
        <div style={{background:'url('+bg_4+') no-repeat center center',backgroundSize:'cover',width:'100%',height:'100%'}}>
        <div style={{zIndex:99,width:'90%',height:'300px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginTop:'200px'}} >
          <div style={{width:'100%',height:'300px'}} >
          <ScrollAnimation animateIn='fadeIn'
            initiallyVisible={true}
            animateOut='fadeOut'>
            <img src={phone4} style={{zIndex:1000,width:'250px'}}/>
          </ScrollAnimation>
          </div>
          <div style={{width:'100%',height:'300px',display:'flex',flexDirection:'column'}} >
          <ScrollAnimation animateIn='flipInY'
            animateOut='flipOutY fadeOut'
            initiallyVisible={true}>
            <h1 style={{width:'100%',color:'#5a97d2',textAlign:'left',textShadow:'#ffffff',fontSize:'20px',marginLeft:'5%'}}>Beyond imagination .</h1>
            <div style={{width:'22%',height:'5px',background:'#5a97d2',borderRadius:10,marginLeft:'5%'}} />
            <h4 style={{width:'100%',textAlign:'left',color:'#5a97d2',fontWeight:'normal',fontSize:'14px',marginLeft:'5%'}}>Boost your creativity: express what your feel in a new and creative way.</h4>
          </ScrollAnimation>
          </div>

          
        </div>
        </div>
      </div>
       <div className="promo3" style={{padding:'0px',marginTop:'-190px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div style={{width:'100%',height:'300px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
            <h1 style={{width:'100%',textAlign:'center',fontSize:'20px'}}>Subscribe for updates</h1>
            <div style={{width:'22%',height:'5px',background:'white',borderRadius:10}} />
            <h4 style={{width:'100%',textAlign:'center',fontWeight:'normal',fontSize:'14px'}}>info (at) svarga-mediatama.com</h4>
             <div style={{display:'flex',flexDirection:'row'}}>
              <a href="https://www.facebook.com/viugraph/" target="_blank">
                <img src={button3} style={{width:40,height:40,marginRight:'10px'}} />
              </a>
              <a href="https://plus.google.com/collection/ww-vIE" target="_blank">
                <img src={button4} style={{width:40,height:40,marginRight:'10px'}} />
              </a>
              <a href="https://twitter.com/ViuGraph" target="_blank">
                <img src={button5} style={{width:40,height:40}} />
              </a>
            </div>
          </div>
      </div>
      <div className='footer' style={{width:'100%',height:'80px',marginTop:'-76px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} >
        {/*<h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>About</h4>*/}
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/svarga-indomulia-mediatama.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>About Us</a>
        </h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/privacy-policy.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>Privacy Policy</a>
        </h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>
          <a href="https://www.viugraph.com/term-of-services.html" target="_blank" style={{color: '#fff', textDecoration: 'none'}}>Term of Services</a>
        </h4>
        {/*<h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',marginRight:'30px',fontSize:'14px'}}>Terms</h4>
        <h4 style={{textAlign:'center',fontWeight:'normal',color:'#ffffff',fontSize:'14px'}}>Contact us</h4>*/}
      </div>
      </ResponsiveComponent>

      </ResponsiveComponent>

    </div>
    );
  }
}

export default App;
